import * as React from "react"
import "reset-css"
import { Layout } from "../components/layout"
import { Container, GlobalCss } from "../theme"

import Seo from "gatsby-plugin-wpgraphql-seo"
import { graphql } from "gatsby"

import styled from "styled-components"




const Content = styled.div`
  p {margin-bottom: 1rem;}

h1, h2, h3, h4, h5 {
  margin: 3rem 0 1.38rem;
  font-family: DM Sans;

  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {font-size: 2.441rem;}

h3 {font-size: 1.953rem;}

h4 {font-size: 1.563rem;}

h5 {font-size: 1.25rem;}

table { > * { font-size: 0.85rem;}}
`;

const ServicesPage = ({ data }) => {
  const {
    page
  } = data

  return (
    <>
      <Seo post={data.page} />
      <GlobalCss />

      <Layout isAlwaysBlue>
        <Container>
          <h1>Privatumo Politika</h1> 
          <Content dangerouslySetInnerHTML={{__html:page.content}} css={`margin-top: 96px;`}/>
        </Container>
      </Layout>
    </>
  )
}
export const query = graphql`
  query {
    page: wpPage(slug: { eq: "privatumo-politika" }) {
      content
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }

    }
  }
`

export default ServicesPage
